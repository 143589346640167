<template>
  <tr :style="leadKey || expanded==item.phone ? 'background: #eee' : ''">
    <td>
      <template v-if="!leadKey">
        <v-checkbox :input-value="selected" style="display: inline-block;vertical-align: middle" @change="onSelect(item)"></v-checkbox>
        {{ index + 1 }}
        <v-chip @click="onExpand(item.phone)" small v-if="item.leads.length>1">
          {{
            (expanded == item.phone ? 'скрыть ' : '+ещё ') + (item.leads.length - 1) + ' ' + $root.numberWord(item.leads.length - 1, ['лид', 'лида', 'лидов'])
          }}
        </v-chip>
      </template>
    </td>
    <td style="width: 170px" :style="!leadKey ? `background: ${$store.state.leadStatuses[item.status].color}` : ''">
      <template v-if="!leadKey">
        {{ $store.state.leadStatuses[item.status].title }}
        <v-btn v-if="lead.type==='call' && item.status!==1" @click="$eventBus.$emit('openLead', item)" color="orange"
               class="mx-1" small icon>
          <v-icon size="16">mdi-pencil</v-icon>
        </v-btn>
        <div v-if="item.status==3 && item.status2*1">({{$store.state.leadStatuses2[item.status2*1].text}})</div>
        <div v-else-if="item.status==4 && item.status3*1">({{$store.state.leadInappropriateType[item.status3*1]}})</div>
        <div v-else-if="item.status==2 && item.calculationSent">(Расчёт отправлен)</div>
      </template>
    </td>
    <td style="width: 130px">{{ $root.dateToRus(lead.datetimeObj.date) }} {{ lead.datetimeObj.time }}</td>
    <td>
      <ClientLink v-if="!leadKey && item.client.phone" :value="item.client"/>
      <span v-else-if="!leadKey">{{ item.phone }}</span>
    </td>
    <td style="padding: 10px">
      <template v-if="lead.type==='order'">
        Заказ
        <OrderLink :value="lead.item"></OrderLink>
        <div v-if="lead.sent_order">
          Заказ в городе: {{ lead.sent_order.orderStatusStr }}, {{ $root.printCost(lead.sent_order.total) }}
          <div style="background: #f1f1f1;padding: 5px 10px;margin-top: 5px;border-radius: 10px;"
               v-if="lead.sent_order.orderStatus == 4">
            {{ lead.sent_order.comment }}
          </div>
        </div>
        <div style="background: #f1f1f1;padding: 5px 10px;margin-top: 5px;border-radius: 10px;"
             v-if="(lead.item.orderStatus == 4 ? lead.item.comment : lead.comment)">
          {{ lead.item.orderStatus == 4 ? lead.item.comment : lead.comment }}
        </div>
      </template>
      <CallInfo v-if="lead.type==='call'" :value="lead.item"/>
      <div v-if="lead.item.utm" style="max-width: 300px">
        <div v-for="(val, key) in lead.item.utm.utm">{{key}}: <span style="color: #2196f3">{{val}}</span></div>
        <div v-if="lead.item.utm.$data && lead.item.utm.$data.first_page">Страница входа: {{lead.item.utm.$data.first_page.url}}</div>
      </div>
      <div style="background: #f1f1f1;padding: 5px 10px;margin-top: 5px;border-radius: 10px;"
           v-if="lead.type==='call' && item.comment && !leadKey">{{ item.comment }}
      </div>
      <template v-if="lead.type==='sms'">
        СМС:
        <div v-html="lead.item.text.split('\n').join('<br>')" style="background: #f1f1f1;padding: 5px 10px;margin-top: 5px;border-radius: 10px;">
        </div>
      </template>
    </td>
    <td>{{ $root.managerName(lead.manager) }}</td>
  </tr>
</template>
<script>
import ClientLink from "./ClientLink";
import OrderLink from "./OrderLink";
import CallInfo from "./CallInfo";

export default {
  name: "LeadRow",
  components: {
    CallInfo,
    OrderLink,
    ClientLink,
  },
  props: ['index', 'item', 'lead', 'leadKey', 'onExpand', 'expanded', 'onSelect', 'selected'],
}
</script>