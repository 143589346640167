<template data-app>
  <v-app>
    <v-container>
      <v-card>
        <v-card-title>Лиды</v-card-title>
        <v-card-actions>
          <DatePeriod @change="getLeads(false)" v-model="date"></DatePeriod>
        </v-card-actions>
        <v-card-text>
          <v-chip @click="section = index;getLeads(false)"
                  v-for="(s, index) in $store.state.user.leadSections" :color="section===index ? '#9BF0F5' : '#fff'"
                  class="mr-2" label>
            {{ s.title }}
          </v-chip>
          <div class="mt-2">
            <v-chip v-if="$store.state.user.leadSections[section].subsections" @click="subsection = i;getLeads(false)"
                    v-for="(s, i) in $store.state.user.leadSections[section].subsections" :color="subsection===i ? '#9BF0F5' : '#fff'"
                    class="mr-2" label>
              {{ s }}
            </v-chip>
          </div>
          <v-alert
              border="top"
              colored-border
              type="info"
              v-if="section===3"
              elevation="2"
          >
            Здесь отображаются заказы, которые завершились 6-7 дней назад с оценкой 5. Через 5 дней после заказа клиентам уходит напоминание, а на 8 день пуш со скидкой. Поэтому за 6-7 дней последняя возможность предложить клиенту заказать уборку без скидки.
          </v-alert>
          <v-data-table
              hover
              :items="rows"
              :headers="headers.filter(h=>this.showStatus || h.value!=='leadStatus')"
              item-key="id"
              class="leads_table"
              :loading="loading"
              :footer-props="{'items-per-page-options':[-1]}"
              no-data-text="Лидов нет"
              loading-text="Загрузка..."
              expand-icon="mdi-chevron-up"
              mobile-breakpoint="0"
          >
            <template v-slot:item="{item, index}">
              <tr>
                <td>{{ index + 1 }}</td>
                <td>
                  <ClientLink :value="item.client"/>
                </td>
                <td><a :href="`#/orders/${item.n_id}`"
                       target="_blank">{{ $moment(item.datetime_createObj.str).format('D MMM HH:mm') }}</a></td>
                <td>
                  <div v-for="row in item.history" style="display: flex;gap: 10px;align-items: center">
                    <div style="display: flex;flex-direction: column;margin-top: 5px">
                      <v-chip style="justify-content: center;margin-bottom: 3px" small>{{ $root.managerName(row.manager) }}</v-chip>
                      <span style="font-size: 12px;color: grey">{{ $moment(row.datetime.str).format('D MMM HH:mm') }}</span>
                    </div>
                    <span>{{ row.comment }}</span>
                  </div>
                </td>
                <td v-if="showStatus">
                  <v-chip color="primary" small>{{ item.leadStatus }}</v-chip>
                </td>
                <td>
                  <template v-if="item.history.length">
                    {{ $root.managerName(item.history[0].manager) }}
                    <template v-if="item.history[0].manager!=item.history[item.history.length-1].manager"> -
                      {{ $root.managerName(item.history[item.history.length - 1].manager) }}
                    </template>
                  </template>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>

import DatePeriod from "../../components/DatePeriod";
import ClientLink from "../../components/ClientLink";
import OrderLink from "../../components/OrderLink";
import LeadRow from "../../components/LeadRow";

export default {
  name: "Leads",
  components: {
    LeadRow,
    OrderLink,
    ClientLink,
    DatePeriod
  },
  data() {
    let today = this.$moment().format('YYYY-MM-DD');
    return {
      headers: [
        {text: '№', value: 'index'},
        {text: 'Клиент', value: 'client.phone'},
        {text: 'Дата', value: 'datetimeObj.str'},
        {text: 'История', value: 'history'},
        {text: 'Статус', value: 'leadStatus'},
        {text: 'Менеджеры', value: 'manager'},
      ],
      loading: false,
      sections: [
        {title: 'Целевые', subsections: ['Все', 'Дорого', 'Надо позже', 'Нет мест', 'Такое не делаем']},
        {title: 'Переданные'},
        {title: 'Корзина', subsections: ['Все', 'Спам', 'По вакансии', 'Другое']},
        {title: 'Лояльные'},
      ],
      rows: [],
      section: 0,
      subsection: 0,
      date: [today, today],
    }
  },
  mounted() {
    this.getLeads()
  },
  computed: {
    showStatus() {
      return ![1, 3].includes(this.section);
    }
  },
  methods: {
    getLeads(firstTime = true) {
      this.$store.state.server.request('lead/get3', {
        date: this.date,
        section: this.section,
        subsection: this.subsection,
      }, (data) => {
        this.rows = data.response;
        this.leads_waiting = data.leads_waiting;
        this.loading = false;
        if (firstTime) setTimeout(() => {
          if (this.$router.currentRoute.path === '/leads') this.getLeads()
        }, 5000)
      }, (data) => {
        if (firstTime) setTimeout(() => {
          if (this.$router.currentRoute.path === '/leads') this.getLeads()
        }, 5000)
        if (data && data.error) this.$root.notify(data.error, 'error')
      })
    },

  },
}
</script>

<style scoped lang="scss">
 .leads_table td:not(:nth-child(4)) {
   width: 0;
   white-space: nowrap;
 }
</style>
