<template>
  <div :style="'color:'+(value.datetime_start*1?(value.callDirection == 'incoming' ? 'green':''): 'red')">
    <v-icon v-if="value.record" @click="$eventBus.$emit('playAudio', value.record)" color="blue">
      mdi-play-circle-outline
    </v-icon>
    <v-icon :color="value.datetime_start*1?(value.callDirection == 'incoming' ? 'green':''): 'red'" size="20">
      mdi-phone
    </v-icon>
    {{
      value.datetime_start * 1 ? (value.callDirection == 'incoming' ? 'Принят ' : 'Исходящий ') + (+value.datetime_end ? $root.printTimeDiff(value.datetime_end - value.datetime_start, true) : '') : 'Пропущен'
    }}
  </div>
</template>
<script>

export default {
  name: "CallInfo",
  props: {
    value: {},
  },
}
</script>
